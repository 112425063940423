import api from "@api";
import route from "@/router";

export default {
    state: {
      
        isDialogOpen: false,
        chapterList: [],
        chapterDto: {
            name: "",
            unitId: "",
            teacherId: "",
            price: 0,
            isFree: false,
            order: 0,
        },
        isFreeStatus: [
            { text: "غير مجاني", value: false, color: "danger" },
            { text: "مجاني", value: true, color: "success" },
        ],
        columns: [
            { label: "اسم البحث", field: "name" },
            { label: "اسم الوحدة", field: "unitName" },
            { label: "اسم المادة", field: "subjectName" },
            { label: "الصف", field: "className" },
            { label: "عدد الدروس", field: "lessonsCount" },
            { label: "عدد الطلاب", field: "studentssCount" },
            { label: "اسم المدرس", field: "teacherName" },
            { label: "السعر", field: "price" },
            { label: "حالة البحث", field: "isFree" },
            { label: "الترتيب", field: "order" },
            { label: "التفاصيل", field: "details" },
        ],
        columnsLesson: [
            { label: "اسم الدرس", field: "name" },
            { label: "نوع الدرس", field: "lessonType" },
            { label: "ترتيب الدرس", field: "order" },
            { label: "عدد الاسئلة", field: "questionsCount" },
            { label: "تاريخ الدرس", field: "dateCreated" },
            { label: "التفاصيل", field: "details" },
        ],
        dtoUpdateChapter: {
            id: "",
            name: "",
            unitId: "",
            unitName: "",
            subjectId: "",
            subjectName: "",
            semester: "",
            teacherId: "",
            teacherName: "",
            price: 0,
            dateCreated: "",
            lessonsCount: 0,
            isFree: true,
            order: 0,
            subCount: 0,
            lessons: [
                {
                    id: "",
                    name: "",
                    dateCreated: "",
                    order: 0,
                    questionsCount: 0,
                    lessonType: 0,
                },
            ],
        },
        chapterOptions: [],
    },
    mutations: {
        SET_CHAPTER(state, payload) {
            state.chapterList = payload;
        },
        ADD_CHAPTER(state, payload) {
            state.chapterList.unshift(payload);
        },
        GET_BYID_CAPTER(state, paylaod) {
            Object.assign(state.dtoUpdateChapter, paylaod);
        },
        UPDATE_CHAPTER(state, paylaod) {
            state.dtoUpdateChapter = { ...paylaod };
        },
        RESET_CHAPTER_DTO(state) {
            state.chapterDto = {
                name: "",
                unitId: "",
                teacherId: "",
                price: 0,
                isFree: false,
                order: 0,
            };
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        SET_CHAPTER_OPTIONS(state, payload) {
            state.chapterOptions = payload;
        },
        DELETE_CHAPTER(state, payload) {
            state.sellPointsList.filter((ele) => ele.id != payload);
            route.push("/admin/chapter");
        },
        DELETE_CHAPTERS(state, payload) {
            state.sellPointsList = state.sellPointsList.filter(
                (el) => !payload.includes(el.id)
            );
        },
    },
    actions: {
        getAllChapters({ commit },search = '') {
            api.get(`Syllabus/Dash/GetAllChapter?search=${search}`, ({ data }) => {
                commit("SET_CHAPTER", data);
                console.log(data);
            });
        },
        addChapter({ commit }, payload) {
            api.post("Syllabus/Dash/AddChapter", payload).then(({ data }) => {
                commit("ADD_CHAPTER", data);
            });
        },
        getByIdChaptert({ commit }, id) {
            api.get(`Syllabus/Dash/GetByIdChapter?id=${id}`, ({ data }) => {
                commit("GET_BYID_CAPTER", data);
            });
        },
        updateChapter({ commit }, paylaod) {
            api.post(
                "Syllabus/Dash/ModifyChapter",
                paylaod,
                ({ data }) => {
                    commit("UPDATE_CHAPTER", data);
                    route.push("/admin/chapter");
                    
                },
                {
                    success: "تم تعديل هذا البحث بنجاح",
                    error: "فشل التعديل ..يرجى اعادة المحاولة ",
                }
            );
        },
        getChapterOptions({ commit }) {
            api.get("Syllabus/Dash/GetChaptersId", ({ data }) => {
                commit("SET_CHAPTER_OPTIONS", data);
            });
        },
        deleteCapter({ commit }, id) {
            api.delete(
                `Syllabus/Dash/DeleteChapter?id=${id}`,
                ({ data }) => {
                    commit("DELETE_CHAPTER", data);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف هذا البحث   ",
                    success: "تم حذف هذا البحث بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteRangeChapters({ commit }, ids) {
            api.delete(
                "Syllabus/Dash/DeleteRangeChapter",
                () => {
                    commit("DELETE_CHAPTERS", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الابحاث المحددة",
                    success: "تم حذف هذه الابحاث بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
